<template>
  <v-container id="mydeliveries" fluid tag="section">
    <orders-view :orders="orders" :last-update="lastUpdate" :error="error" :no-result-title="title" :no-result-description="description"
                 mode="delivery" :show-order-counter="false" @refresh="refreshOrders"
    />
    <production-tasks-view role="driver" />
  </v-container>
</template>

<script>
  import { Logger } from '@/services/common/Logger';
  import { getPageName, handleRequestError } from '@/services/common/Http';
  import { displayHourISO } from '@/util/DateUtil';
  import { geolocation, geolocationIsAllowed, notification } from '@/util/DeviceUtil';
  import OrderState from '@/services/order/OrderState';
  import OrdersView from '@/views/components/business/order/OrdersView';
  import ProductionTasksView from '@/views/components/business/productionTask/ProductionTasksView';

  export default {
    name: 'MyDelivery',
    components: { OrdersView, ProductionTasksView },
    data () {
      return {
        deliveryTour: [],
        deliveryTourIsComplete: false,
        deliveryTourNotification: false,
        error: '',
        lastUpdate: 0,
        orders: [],
        showOrders: true
      };
    },
    computed: {
      title: function () {
        return this.$i18n.t('deliverytour.instruction.notready');
      },
      description: function () {
        if (!this.deliveryTourIsComplete && this.deliveryTour.length > 0) {
          return this.$i18n.t('deliverytour.instruction.wait');
        }

        return this.$i18n.t('deliverytour.instruction.empty');
      }
    },
    mounted () {
      geolocation(async (latitude, longitude) => {
        try {
          await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'user/position', {
            latitude: latitude,
            longitude: longitude
          });
        } catch (error) {
          Logger.exception(error);
        }
      });
      window.addEventListener('beforeunload', this.beforeUnload);
    },
    destroyed () {
      geolocation();
      if (this.deliveryTour.length === 0) {
        window.removeEventListener('beforeunload', this.beforeUnload);
      }
    },
    methods: {
      async beforeUnload (e) {
        try {
          if (!(await geolocationIsAllowed())) {
            return false;
          }
        } catch (error) {
          Logger.exception(error);
        }

        const event = e || window.event;
        if ((this.deliveryTour.length > 0 || this.deliveryTourIsComplete) && getPageName() === 'mydelivery') {
          event.preventDefault();
          event.returnValue = this.$i18n.t('delivery.sub.my.inProgress');

          confirm(this.$i18n.t('delivery.sub.my.inProgress'));

          return this.$i18n.t('delivery.sub.my.inProgress');
        }
      },
      async refreshOrders () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'orders/mydeliveries/');
          this.deliveryTourIsComplete = response.data.isComplete;
          this.deliveryTour = response.data.orders;
          const hasOrdersInDelivery = this.deliveryTour.find((order) => [OrderState.delivery, OrderState.next_delivery, OrderState.arriving].includes(order.state.state));
          this.orders = hasOrdersInDelivery || this.deliveryTourIsComplete ? this.deliveryTour : [];
          this.showOrders = (this.orders.length > 0);
          this.lastUpdate = Date.now();
          this.error = '';
          this.notify();
        } catch (error) {
          this.error = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('deliverytour.error.fail'));
        }
      },
      notify () {
        const hasOrdersInDelivery = this.deliveryTour.find((order) => order.state.state === OrderState.delivery) !== undefined;
        if (!hasOrdersInDelivery && this.deliveryTourIsComplete) {
          if (!this.deliveryTourNotification) {
            notification(this.$i18n.t('deliverytour.notification.title', { date: displayHourISO((new Date()).toISOString()) }),
                         this.$i18n.t('deliverytour.notification.description', { count: this.deliveryTour.length }), () => {
                           this.deliveryTourNotification = true;
                         }, Logger.exception);
          }
        } else {
          this.deliveryTourNotification = false;
        }
      }
    }
  };
</script>
